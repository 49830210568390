import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/firebase";
import Dashboard from "./components/Dashboard";
import AIQuiz from "./components/AIQuiz";
import BuilderAI from "./components/BuilderAI";
import Profile from "./components/Profile";
import UserHistory from "./components/UserHistory";
import "./App.css"; // Import your CSS file for styling

function App() {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Handle authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser || null);
    });
    return () => unsubscribe();
  }, []);

  // Sign in with Google
  const SignUpWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  // Landing Page Component
  const LandingPage = ({ onSignIn }) => {
    return (
      <div className="landing-page">
        <header className="header">
          <div className="logo">
            <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_6_330)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 0.757L47.243 24 24 47.243.757 24 24 0.757zM21 35.757V12.243L9.243 24 21 35.757z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_6_330"><rect width="48" height="48" fill="white"></rect></clipPath>
              </defs>
            </svg>
            <h2>Focus2Flow</h2>
          </div>
          <div className="nav">
            <a href="#">Home</a>
            <a href="#">Dashboard</a>
            <a href="#">AI Content</a>
            <a href="#">Content Library</a>
            <a href="#">Pricing</a>
          </div>
          <div className="actions">
            <button className="btn-primary">New Content</button>
            <button className="btn-secondary" onClick={onSignIn}>
          <  img
    src="/google-logo.svg"
    alt="Google Logo"
    style={{ width: "20px", marginRight: "8px" }}
  />LOGIN</button>
          </div>
        </header>
        <div className="hero">
          <div className="hero-content">
            <h1>Create AI-generated content in minutes</h1>
            <p>Generate notes, lessons, and more with Focus2Flow. Perfect for students, teachers, and lifelong learners.</p>
            <div className="search-bar">
              <input type="text" placeholder="What do you want to learn?" />
              <button>Search</button>
            </div>
          </div>
        </div>
        <div className="content">
          <h2>Who uses Focus2Flow?</h2>
          <div className="grid">
            <div className="card">
              <div className="card-image" style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/5b7515d9-f42c-4e39-b809-9a2ff05644c2.png")' }}></div>
              <p className="card-title">Students</p>
              <p className="card-description">Generate study notes and lessons to help you learn more effectively.</p>
            </div>
            <div className="card">
              <div className="card-image" style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/1e35f454-45e5-41ab-8507-4e2e9c042ffc.png")' }}></div>
              <p className="card-title">Teachers</p>
              <p className="card-description">Create AI-generated lessons and content for your students.</p>
            </div>
            <div className="card">
              <div className="card-image" style={{ backgroundImage: 'url("https://cdn.usegalileo.ai/sdxl10/a057a540-a863-4536-8fa1-289c7b42cd99.png")' }}></div>
              <p className="card-title">Lifelong Learners</p>
              <p className="card-description">Explore AI-generated content to learn new skills and topics.</p>
            </div>
          </div>
        </div>
        <div className="coming-soon">
          <h2>Coming Soon</h2>
          <p>We're working on exciting new features, including more AI-generated content like quizzes, flashcards, and interactive lessons. Stay tuned!</p>
        </div>
      </div>
    );
  };

  // Render Landing Page if user is not authenticated
  if (!user) {
    return <LandingPage onSignIn={SignUpWithGoogle} />;
  }

  // Render Role Selection if user is authenticated but role is not selected
  if (!userRole) {
    return (
      <div className="role-selection">
        <h2>Select Your Role</h2>
        <div className="role-buttons">
          <button onClick={() => setUserRole("student")}>I am a Student</button>
          <button onClick={() => setUserRole("educator")}>I am an Educator</button>
        </div>
      </div>
    );
  }

  // Render Dashboard and Routes if user is authenticated and role is selected
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Dashboard userRole={userRole} user={user} />}
        >
          <Route
            index
            element={userRole === "student" ? <AIQuiz /> : <BuilderAI />}
          />
          <Route path="/courses" element={<AIQuiz />} /> {/* Route for Courses */}
          <Route path="/notes" element={<AIQuiz />} /> {/* Route for Notes */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/history" element={<UserHistory />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;