import React, { useState } from 'react';
import { auth, db } from '../config/firebase';
import { collection, addDoc } from 'firebase/firestore';

const styles = `
  .app {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .header {
    text-align: center;
    margin-bottom: 30px;
  }

  .header h1 {
    font-size: 42px;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 10px;
  }

  .header p {
    font-size: 18px;
    color: #7f8c8d;
  }

  .search-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .search-bar input {
    width: 400px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
  }

  .search-bar input:focus {
    border-color: #1abc9c;
  }

  .subjects {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .subjects button {
    background: linear-gradient(135deg, #1abc9c, #16a085);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .subjects button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .courses {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .course-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .course-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .course-card h3 {
    font-size: 24px;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 10px;
  }

  .course-card p {
    font-size: 16px;
    color: #7f8c8d;
    margin-bottom: 10px;
  }

  .course-card button {
    background: linear-gradient(135deg, #1abc9c, #16a085);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .course-card button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .loading {
    text-align: center;
    padding: 20px;
    color: #7f8c8d;
    font-size: 18px;
  }

  .lesson-content {
    background: white;
    padding: 20px;
    border-radius: 12px;
    line-height: 1.6;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .lesson-content p {
    font-size: 16px;
    color: #2c3e50;
  }

  .lesson-image {
    max-width: 100%;
    border-radius: 12px;
    margin-top: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .section {
    margin-top: 30px;
    text-align: center;
  }

  .section-title {
    font-size: 24px;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 20px;
  }

  .input {
    width: 300px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
  }

  .input:focus {
    border-color: #1abc9c;
  }

  .button {
    background: linear-gradient(135deg, #1abc9c, #16a085);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }

  .button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .button:disabled {
    background: #bdc3c7;
    cursor: not-allowed;
  }
`;

const API_URL = 'https://server-f2f.onrender.com/api';

const subjects = [
  'Mathematics', 'Physics', 'Chemistry', 'Biology', 'History',
  'Literature', 'Computer Science', 'Art', 'Music', 'Philosophy'
];

export default function AIQuiz() {
  const [selectedSubject, setSelectedSubject] = useState('');
  const [subtopics, setSubtopics] = useState([]);
  const [selectedSubtopic, setSelectedSubtopic] = useState('');
  const [lesson, setLesson] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [customSubject, setCustomSubject] = useState('');
  const [mode, setMode] = useState('regular');
  const [kidsImage, setKidsImage] = useState('');

  const saveToHistory = async (content, title) => {
    try {
      await addDoc(collection(db, 'history'), {
        userId: auth.currentUser.uid,
        content,
        type: 'lesson',
        title,
        timestamp: new Date(),
        isBookmarked: false,
        note: ''
      });
    } catch (error) {
      console.error('Error saving to history:', error);
    }
  };

  const generateSubtopics = async (subject) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/subtopics`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subject })
      });
      const data = await response.json();
      setSubtopics(data.subtopics);
    } catch (error) {
      console.error('Error:', error);
      setSubtopics([]);
    }
    setIsLoading(false);
  };

  const generateLesson = async () => {
    if (!selectedSubject || !selectedSubtopic) {
      console.error('Subject or subtopic is missing');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/lesson`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          subject: selectedSubject,
          subtopic: selectedSubtopic,
          mode
        })
      });
      const data = await response.json();
      setLesson(data.lesson.replace(/\./g, '.<br>'));
      if (data.image) {
        setKidsImage(data.image);
      }
      await saveToHistory(data.lesson, 'lesson', selectedSubtopic);
    } catch (error) {
      console.error('Error:', error);
      setLesson('');
    }
    setIsLoading(false);
  };

  const handleSubtopicClick = (subtopic) => {
    setSelectedSubtopic(subtopic);
    generateLesson(); // Generate lesson immediately after selecting a subtopic
  };

  return (
    <>
      <style>{styles}</style>
      <div className="app">
        <div className="header">
          <h1>My Academy</h1>
          <p>Learn, Teach, Explore</p>
        </div>

        <div className="search-bar">
          <input type="text" placeholder="Search your library" />
        </div>

        <div className="subjects">
          {subjects.map(subject => (
            <button
              key={subject}
              onClick={() => {
                setSelectedSubject(subject);
                generateSubtopics(subject);
              }}
            >
              {subject}
            </button>
          ))}
        </div>

        {subtopics.length > 0 && (
          <div className="subjects">
            {subtopics.map((subtopic, index) => (
              <button
                key={index}
                onClick={() => handleSubtopicClick(subtopic)}
              >
                {subtopic}
              </button>
            ))}
          </div>
        )}

        {isLoading ? (
          <div className="loading">Loading...</div>
        ) : (
          lesson && (
            <div className="course-card">
              <div className="lesson-content">
                <div dangerouslySetInnerHTML={{ __html: lesson }} />
                {kidsImage && (
                  <img src={kidsImage} alt="Lesson illustration" className="lesson-image" />
                )}
              </div>
            </div>
          )
        )}

        <div className="section">
          <h3 className="section-title">Custom Subject</h3>
          <input
            className="input"
            placeholder="Enter subject name"
            value={customSubject}
            onChange={(e) => setCustomSubject(e.target.value)}
          />
          <button
            className="button"
            onClick={() => {
              setSelectedSubject(customSubject);
              generateSubtopics(customSubject);
            }}
            disabled={!customSubject}
          >
            Create Custom Subject
          </button>
        </div>
      </div>
    </>
  );
}